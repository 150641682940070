import React, { FC, ReactNode, useEffect } from 'react';
import Navigation from 'components/Navigation';
import Head from 'components/Head';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';
import { animated } from '@react-spring/web';
import { useUserPilotReload } from 'app/hooks/useUserPilotReload';
import BackdropLoader from 'app/components/BackdropLoader';
import { useLastPaymentStatusBanner } from './useLastPaymentStatusBanner';
import NetworkStatus from 'app/components/NetworkStatus';
import clsx from 'clsx';
import UserMobileNavigation from './UserMobileNavigation';

type Props = {
  children: ReactNode | ReactNode[];
};

const AppLayout: FC<Props> = (props) => {
  const { isNavigationExpanded, backdropLoaderShown } = useAppLayoutState();

  useEffect(() => {
    document.body.dataset.navigationExpanded = `${isNavigationExpanded}`;
  }, [isNavigationExpanded]);

  useUserPilotReload();
  useLastPaymentStatusBanner();

  return (
    <>
      <Head />
      <div
        className="tw-relative tw-flex tw-flex-1 tw-overflow-hidden"
        data-cy="app-layout"
      >
        <Navigation />
        <animated.main
          className={clsx('bg-gradient-primary tw-w-full', {
            'sm:tw-pl-[64px]': !isNavigationExpanded,
            'sm:tw-pl-[260px]': isNavigationExpanded,
          })}
        >
          <UserMobileNavigation />

          {props.children}
        </animated.main>
        <NetworkStatus />
      </div>
      {backdropLoaderShown && <BackdropLoader />}
    </>
  );
};

export default AppLayout;
