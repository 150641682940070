import PublicUtils from 'components/publicPreviews/publicUtils';
import React, { FC } from 'react';
import { preventDefault } from 'utils/mouseEvent';
import { NavigationItem } from '../utils/libs';
import { useModalHistory } from 'context/ModalRouter';
import { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import Link from 'components/shared/Link';
import { getNavigationItemUrl } from '../verticalNavigation/mainMenu/MenuItems';
import MobileNavigationMenuItem from './MobileNavigationMenuItem';

type Props = {
  isPublic?: boolean;
  menuItems: NavigationItem[];
  activeMenuItem?: NavigationItem;
  setMobileMenuOpen: (isOpen: boolean) => void;
};

export const MobileNavigationMenu: FC<Props> = ({
  isPublic,
  menuItems,
  activeMenuItem,
  setMobileMenuOpen,
}) => {
  const history = useModalHistory();

  return (
    <>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-start">
        {isPublic
          ? PublicUtils.publicMock.menuItems.map((item, index) => (
              <div
                key={`${item.key}-${index}`}
                className="tw-flex tw-flex-col tw-gap-y-4"
                onClick={preventDefault(() => {
                  setMobileMenuOpen(false);

                  history.push({
                    hash: modalRoutes.PUBLIC_INVITE_USER,
                    state: { actionType: 'navbar' },
                  });
                })}
              >
                <MobileNavigationMenuItem
                  item={item}
                  activeMenuItem={activeMenuItem}
                />
              </div>
            ))
          : menuItems
              .filter((item) => !item.isDisabled)
              .map((item, index) => (
                <UserPermissionGate
                  key={`${item.key}-${index}`}
                  scopes={item.permissionsScope}
                  exact={false}
                >
                  <Link
                    onClick={(e) => {
                      setMobileMenuOpen(false);
                      if (!(item.url || item.routerUrl)) {
                        e.preventDefault();
                      }
                    }}
                    isRouterLink={!!item.routerUrl}
                    to={getNavigationItemUrl(item)}
                    className="tw-w-full"
                    data-name={`navigation-link-${item.key}`}
                  >
                    <MobileNavigationMenuItem
                      item={item}
                      activeMenuItem={activeMenuItem}
                    />
                  </Link>
                </UserPermissionGate>
              ))}
      </div>
    </>
  );
};
