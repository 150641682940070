import { FC } from 'react';
import { Chip, Icon } from '@kontentino/ui';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
type Props = {
  notificationsCount: number;
};

const InAppNotificationsIcon: FC<Props> = ({ notificationsCount }) => (
  <div className="tw-relative">
    <Icon icon={faBell} className="!tw-flex" />
    {notificationsCount > 0 && (
      <div className="tw-absolute -tw-top-4 tw-left-1">
        <Chip variant="alert">{notificationsCount}</Chip>
      </div>
    )}
  </div>
);

export default InAppNotificationsIcon;
