import React, { FC } from 'react';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { useQuery } from 'react-query';
import PostApi from 'api/post';
import Skeleton from 'react-loading-skeleton';
import { Divider, Icon } from '@kontentino/ui';
import Link from 'components/shared/Link';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { queryKey } from 'constants/queryKey';
import PublicPostPreview from 'components/publicPreviews/post/PublicPostPreview';
import { useTranslation } from 'react-i18next';

type Props = {
  token: string;
  ids: number[];
};

export const PublicPostPreviewsList: FC<Props> = ({ ids, token }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(
    queryKey.publicPostPreview({ id: ids[0], token }),
    () => PostApi.publicPreview(ids[0], token),
    {
      onSuccess(data) {
        PublicUtils.setSharedData(data.user);
      },
    },
  );

  return (
    <div
      className="bg-gradient-primary tw-grow-1 tw-flex tw-h-full tw-flex-col tw-overflow-auto"
      data-cy="public-post-preview"
    >
      {data && (
        <div className="tw-sticky tw-top-0 tw-z-10 tw-flex tw-flex-wrap tw-justify-between tw-gap-2 tw-border-b tw-border-b-grayscale-20 tw-bg-white/90 tw-px-6 tw-py-4 tw-backdrop-blur">
          <span
            className="tw-text-base tw-font-semibold  md:tw-text-lg"
            data-cy="public-post-preview_sharedBy"
          >
            {t('userHasSharedThisPostWithYour', {
              user: data.user.name,
              count: ids.length,
            })}
          </span>
          <Link
            to="https://www.kontentino.com"
            data-name="public-link-more-about-kont"
            data-cy="public-link-more-about-kont"
            className="tw-flex tw-items-center"
          >
            <Icon
              icon={faCircleInfo}
              className="tw-mr-2 tw-text-grayscale-120"
            />
            <span className="tw-text-base tw-text-grayscale-180">
              {t('learnMoreAboutKontentino')}
            </span>
          </Link>
        </div>
      )}
      <div className="tw-space-y-8">
        {ids.map((id, index) => (
          <React.Fragment key={id}>
            <PublicPostPreview id={id} token={token} />
            {index !== ids.length - 1 && <Divider className="!tw-my-20" />}
          </React.Fragment>
        ))}
      </div>
      {isLoading && <Skeleton width="100%" height="300px" />}
    </div>
  );
};

export default PublicPostPreviewsList;
