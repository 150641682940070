import React, { FC, useMemo } from 'react';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';
import { MobileNavigation } from './navigation/MobileNavigation';
import { useAccount } from 'modules/account/accountSelector';
import { useLocation } from 'react-router-dom';
import { useUser } from 'modules/user/userSelector';
import { getNavigationItems } from './navigation/utils/libs';
import useInAppNotificationsCountQuery from 'app/modules/inAppNotifications/hooks/useInAppNotificationsCountQuery';
import { useLogoutUser } from 'modules/app/appSelector';

type Props = {};

const UserMobileNavigation: FC<Props> = (props) => {
  const user = useUser();
  const { pathname } = useLocation();

  const account = useAccount();
  const { insightSelectedPageType } = useAppLayoutState();
  const items = getNavigationItems({
    account,
    user,
    insightSelectedPageType,
  });
  const { data: notificationsData } = useInAppNotificationsCountQuery();
  const logoutUser = useLogoutUser();
  const { isSubscription } = useAccount();
  const activeMenuItem = useMemo(
    () =>
      items.find(
        (item) =>
          (item.routerUrl && pathname === item.routerUrl) ||
          pathname.includes(`${item.routerUrl}/`),
      ),
    [pathname, items],
  );
  return (
    <MobileNavigation
      menuItems={items}
      user={user}
      isSubscription={isSubscription}
      activeItem={activeMenuItem}
      notificationsData={notificationsData}
      logoutUser={logoutUser}
    />
  );
};

export default UserMobileNavigation;
